<template>
  <v-container fluid>
    <v-row v-if="isLoaded">
      <v-col cols="12" class="mt-5">

        <v-row justify="start">

          <v-col cols="12">
            <v-row justify="space-between" class="mx-2" no-gutters>
              <div class="display-1">{{item.person.firstname | truncate}} {{item.person.name | truncate}}</div>

              <div>

                <!-- State -->
                <v-chip v-if="item.enabled" label outlined color="success" class="mr-2">
                  <v-icon left>fas fa-check</v-icon>
                  <div class="font-weight-medium">{{$t('common.misc.enabled')}}</div>
                </v-chip>

                <v-chip v-else label outlined color="error" class="mr-2">
                  <v-icon left>fas fa-times</v-icon>
                  <div class="font-weight-medium">{{$t('common.misc.disabled')}}</div>
                </v-chip>

                <!-- Edit -->
                <v-btn icon @click="showEditDialog">
                  <v-icon>fas fa-edit</v-icon>
                </v-btn>

                <!-- Edit usages --
                <v-btn icon @click="openUsagesDialog">
                  <v-icon>fas fa-tally</v-icon>
                </v-btn-->

                <!-- Delete -->
                <v-btn icon @click="showDeleteDialog">
                  <v-icon>fas fa-trash</v-icon>
                </v-btn>

              </div>
            </v-row>

            <v-divider />
          </v-col>

          <v-col cols="12">
            <v-row no-gutters>

              <!-- Details -->
              <v-col cols="12" class="my-6">

                <v-card>
                  <v-card-title>{{$t('subscriptions.titles.details')}}</v-card-title>
                  <v-card-text style="font-size: 16px !important;">

                    <!-- Ref -->
                    <div>
                      <span>{{$t('subscriptions.inputs.ref')}}: </span>
                      <span class="font-weight-bold">{{item.ref}}</span>
                    </div>

                    <!-- Email -->
                    <div>
                      <span>{{$t('subscriptions.inputs.email')}}: </span>
                      <span class="font-weight-bold">{{item.email}}</span>
                    </div>

                    <!-- Plan -->
                    <div>
                      <span>{{$t('subscriptions.inputs.plan')}}: </span>
                      <span class="font-weight-bold">{{item.subscription.plan}}</span>
                    </div>

                    <!-- Lang -->
                    <div>
                      <span>{{$t('subscriptions.inputs.lang')}}: </span>
                      <span class="font-weight-bold">{{item.preferences.lang}}</span>
                    </div>

                    <!-- Date -->
                    <div class="font-weight-bold">
                      {{$t('subscriptions.inputs.member_since')}} {{$moment(item.createdAt).format("DD/MM/YYYY")}}
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

              <!-- Subscription -->
              <v-col cols="12" class="my-6">
                <v-card>
                  <v-card-title>{{$t('subscriptions.titles.subscription')}}</v-card-title>
                  <v-card-text style="font-size: 16px !important;">

                    <!-- Start -->
                    <div v-if="item.subscription.dates.start">
                      <span>{{$t('subscriptions.inputs.date_start')}}: </span>
                      <span class="font-weight-bold">{{$moment(item.subscription.dates.start).format("DD/MM/YYYY")}}</span>
                    </div>

                    <!-- End -->
                    <div v-if="item.subscription.dates.end">
                      <span>{{$t('subscriptions.inputs.date_end')}}: </span>
                      <span class="font-weight-bold">{{$moment(item.subscription.dates.end).format("DD/MM/YYYY")}}</span>
                    </div>

                    <!-- Next renewal -->
                    <div v-if="item.subscription.dates.next_occurrence">
                      <span>{{$t('subscriptions.inputs.next_occurrence')}}: </span>
                      <span class="font-weight-bold">{{$moment(item.subscription.dates.next_occurrence).format("DD/MM/YYYY")}}</span>
                    </div>

                    <!-- Phone -->
                    <div>
                      <span>{{$t('subscriptions.inputs.phone')}}: </span>
                      <span class="font-weight-bold">{{item.subscription.billing.phone}}</span>
                    </div>

                    <!-- Email -->
                    <div>
                      <span>{{$t('subscriptions.inputs.email')}}: </span>
                      <span class="font-weight-bold">{{item.subscription.billing.email}}</span>
                    </div>

                    <!-- VAT Number -->
                    <div>
                      <span>{{$t('subscriptions.inputs.billing.vat_number')}}: </span>
                      <span class="font-weight-bold">{{item.subscription.billing.vat_number}}</span>
                    </div>

                    <!-- Address -->
                    <div>
                      <span>{{$t('subscriptions.inputs.address_')}}: </span>
                      <span class="font-weight-bold">{{item.subscription.billing.address.street ? item.subscription.billing.address.street : ""}}</span>
                      <span v-if="item.subscription.billing.address.additional_street">, {{item.subscription.billing.address.additional_street}}</span>
                      <span v-if="item.subscription.billing.address.locality" class="ml-1">{{item.subscription.billing.address.locality}}</span>
                      <span v-if="item.subscription.billing.address.postal_code" class="ml-1">{{item.subscription.billing.address.postal_code}}</span>
                      <span class="ml-1 text-uppercase">{{countryFormatter(item.subscription.billing.address.country)}}</span>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- Edit dialog -->
    <EditDialog
      v-if="isLoaded && toggleEditDialog"
      v-model="toggleEditDialog"
      :item="item"
      :isLoading="isLoading.btnSave"
      v-on:submit="customSubmit"
      v-on:close="toggleEditDialog = false"/>


    <!-- Delete dialog -->
    <DeleteDialog
      v-if="isLoaded && toggleDeleteDialog"
      v-model="toggleDeleteDialog"
      v-on:submit="DataDeleteOne"
      v-on:close="toggleDeleteDialog = false"/>


  </v-container>
</template>

<script>
  import dataMixin from "@/mixins/data";
  import formatterMixin from "@/mixins/formatter";
  import utils from "@/mixins/utils";

  export default {

    name: "Subscription",

    mixins: [dataMixin, formatterMixin, utils],

    components: {

      EditDialog: () => import("@/components/Subscriptions/EditDialog"),
      DeleteDialog: () => import("@/components/DeleteDialog"),
    },

    data() {

      return {
        usages: null,

        toggleEditUsagesDialog: false,

        ROUTE: {
          ALL: "subscriptions"
        },

        URL_API: {
          GET_ONE: `/users/${this.$route.params.id}`,
          EDIT: `/users/${this.$route.params.id}`,
          EDIT_USAGES: `/users/${this.$route.params.id}/usages`,
          DELETE: `/users/${this.$route.params.id}`
        },
      }
    },

    created() {
      this.getData();
    },

    methods: {

      customSubmit(newItem) {

        this.$http.put(this.URL_API.EDIT, newItem,
        {
          headers:
          {
            Authorization: "Bearer " + this.$session.get('jwt')
          }
        })
        .then(data => {
          this.toggleEditDialog = false;
          this.getData();
        })
        .catch(err => {
          this.$store.commit("toast/showError", this.$t('common.errors.500'));
        })
      },

      getData() {

        this.isLoaded = false;

        this.$http.get(this.URL_API.GET_ONE,
        {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res => {
          this.item = res.data;
          //this.usages = res.data.usages;
        })
        .catch(err => {
          this.$store.commit("toast/showError", this.$t('common.errors.500'));
        })
        .finally(() => {
          this.isLoaded = true;
        })
      },

    },

    computed: {

    }
  }
</script>

<style scoped>

</style>